import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikConfig, FormikValues, Field, useField } from 'formik';
import * as Yup from 'yup';
import { getCurrentSession } from '../../elements/ModalContactForm';

//FORMIK STEPPER
export interface FormikStepProps extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }: FormikConfig<FormikValues>) {
  const { allAnswers, errorMessage } = props;
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [currentlyWear, setCurrentlyWear] = useState(true);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  useEffect(() => {
    Object.entries(allAnswers).forEach((entry) => {
      const [key, value] = entry;
      if (key === 'Do you currently wear hearing aids?') {
        if (value === 'Yes') {
          if (currentlyWear !== true) {
            setCurrentlyWear(true);
          }
        } else {
          if (currentlyWear !== false) {
            setCurrentlyWear(false);
          }
        }
      }
    });
  }, [allAnswers]);

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        }
      }}
    >
      {({ values, isSubmitting, isValid, dirty }) => (
        <Form autoComplete="off">
          <div className="mb-[32px] md:mb-[70px]">
            {errorMessage?.length > 0 && (
              <div
                className="inline-block p-4 border text-error border-error rounded-[4px] mt-4"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              />
            )}
          </div>
          <input type="hidden" name="source" value="Rw8k051Gs71RmgcwIQSF" />
          <input type="hidden" name="session" value={getCurrentSession()} />

          {currentChild}

          <div className="flex flex-col space-y-4 w-full max-w-[384px] mx-auto">
            {isLastStep() ? (
              <button
                disabled={isSubmitting || !(isValid && dirty)}
                className="bg-blue w-full text-white rounded-[50px] card-shadow py-3 px-8 text-center border border-white transition-colors duration-300 ease-in-out hover:border-white hover:bg-green disabled:opacity-40"
                type="submit"
              >
                {isSubmitting ? (
                  <div className="t-16 font-heading font-bold">Submitting</div>
                ) : (
                  <div className="t-16 font-heading font-bold">See my recommendations</div>
                )}
              </button>
            ) : (
              <button
                disabled={isSubmitting || !(isValid && dirty)}
                className=" bg-white w-full text-navy rounded-[50px] card-shadow py-3 px-8 text-center border border-white transition-colors duration-300 ease-in-out hover:border-blue disabled:opacity-40"
                type="button"
                onClick={() => {
                  if (!currentlyWear && step === 0) {
                    setStep((s) => 3);
                  } else {
                    setStep((s) => s + 1);
                  }
                }}
              >
                <div className="t-16 font-heading font-bold">Continue</div>
              </button>
            )}
            {step > 0 && !isLastStep() && (
              <button
                disabled={isSubmitting}
                className=" bg-blue w-full text-white rounded-[50px] card-shadow py-3 px-8 text-center border border-white transition-colors duration-300 ease-in-out hover:border-white"
                type="button"
                onClick={() => {
                  if (!currentlyWear && step === 3) {
                    setStep((s) => 0);
                  } else {
                    setStep((s) => s - 1);
                  }
                }}
              >
                <div className="t-16 font-heading font-bold">Previous</div>
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
