import React, { useState, useEffect } from 'react';
import Link from 'gatsby-link';
import * as Yup from 'yup';
import { StaticImage } from 'gatsby-plugin-image';
import FindYourProductData from '../../data/find-your-product.json';
import { InteractiveMap } from '~/components/flexible/InteractiveMap';
import { AnimatePresence, motion } from 'framer-motion';
import { Field, useField } from 'formik';
import { FormikStepper, FormikStep } from './findyourproduct/FormIkStepper';

import { Check, Cross, Pin } from '~/components/elements/Icon';

import { useAllProducts } from '../../utils/getProducts';
import { SingleProduct } from './findyourproduct/SingleProduct';
import { findYourProductResults } from './findyourproduct/ProductResults';

export const FindYourProduct = (page) => {
  const [active, setActive] = useState(0);

  return (
    <section className="bg-blue-light">
      <div className="container pt-20 pb-20 ">
        <AnimatePresence>
          <FindYourProductQuiz questions={FindYourProductData} />
        </AnimatePresence>
      </div>
    </section>
  );
};

export const FindYourProductQuiz = ({ questions }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [allAnswers, setAllAnswers] = useState({});
  const [submitForm, setSubmitForm] = useState(false);
  const [allProducts, setAllProducts] = useState(useAllProducts());
  const [productResults, setProductResults] = useState([]);

  useEffect(() => {
    function getResults() {
      const results = findYourProductResults(allAnswers, allProducts);
      setProductResults(results);
    }
    getResults();
  }, [submitForm]);

  return (
    <>
      {submitForm === false ? (
        <FormikStepper
          initialValues={{
            'doyoucurrentlywearhearingaids?': '',
          }}
          onSubmit={async (values) => {
            setSubmitForm(true);
            await new Promise((r) => setTimeout(r, 500));
            //alert(JSON.stringify(values, null, 2));
          }}
          allAnswers={allAnswers}
          errorMessage={errorMessage}
        >
          {questions?.map((question, index) => {
            return (
              <FormikStep key={`question-${index}`} label={question?.question}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    ease: 'easeInOut',
                    default: { duration: 0.3 },
                  }}
                >
                  <div className="text-center min-h-[600px] flex flex-col items-center justify-center space-y-12 mb-12">
                    {question?.type === 'map' ? (
                      <div>
                        <InteractiveMap
                          heading="In home service, wherever you are"
                          content="See if we're in your area by checking the map to your left. If we aren't in your area yet, don't worry — we're rapidly expanding our services, so make sure to check back soon!"
                          showForm={true}
                        />
                      </div>
                    ) : question?.type === 'information' ? (
                      <InformationQuestion />
                    ) : question?.type === 'details' ? (
                      <UserDetails
                        index={index}
                        question={question?.question}
                        questionsLength={questions.length}
                        allAnswers={allAnswers}
                        setAllAnswers={setAllAnswers}
                      />
                    ) : (
                      <>
                        <div className="space-y-4 max-w-[628px] mx-auto">
                          <div className="t-14 font-semibold text-blue">
                            {index + 1} of {questions.length}
                          </div>
                          <h2 className="t-42">{question?.question}</h2>
                        </div>
                        {question?.type === 'true-false' ? (
                          <TrueFalse
                            question={question?.question}
                            allAnswers={allAnswers}
                            setAllAnswers={setAllAnswers}
                          />
                        ) : question?.type === 'select' ? (
                          <Select
                            options={question?.options}
                            question={question?.question}
                            allAnswers={allAnswers}
                            setAllAnswers={setAllAnswers}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </motion.div>
              </FormikStep>
            );
          })}
        </FormikStepper>
      ) : (
        <div className="text-center min-h-[600px] flex flex-col items-center justify-center space-y-12 my-12">
          {productResults ? (
            <div>
              <div className="space-y-4 max-w-[628px] mx-auto">
                <div className="t-14 font-semibold text-blue">Your Products</div>
                <h2 className="t-42">Your custom recommendations</h2>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 text-left">
                {productResults?.map((product, i) => {
                  return (
                    <div className="hover:bg-white duration-300 p-7 rounded-[10px]">
                      <SingleProduct
                        title={product?.name}
                        key={i}
                        slug={product?.slug}
                        ratings={product?.reviews?.averageRating}
                        image={product?.featuredImage?.node}
                        price={product?.price}
                        showPrice={product?.postTypeProduct?.showPrice}
                        productTag={product?.postTypeProduct?.tag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <h2 className="t-42">Loading...</h2>
          )}
        </div>
      )}
    </>
  );
};

export const TrueFalse = ({ question, allAnswers, setAllAnswers }) => {
  const [checked, setChecked] = useState(false);
  const [field, meta, helpers] = useField(question?.toLowerCase().replace(/\s/g, ''));
  const { setValue } = helpers;
  return (
    <div className="grid grid-cols-2 w-full max-w-[342px] mx-auto gap-x-6">
      <SelectOption
        label={'Yes'}
        showIcon={true}
        icon={true}
        question={question}
        checked={checked}
        setChecked={setChecked}
        allAnswers={allAnswers}
        setAllAnswers={setAllAnswers}
        setValue={setValue}
      />
      <SelectOption
        label={'No'}
        showIcon={true}
        icon={false}
        question={question}
        checked={checked}
        setChecked={setChecked}
        allAnswers={allAnswers}
        setAllAnswers={setAllAnswers}
        setValue={setValue}
      />
    </div>
  );
};

export const Select = ({ options, question, allAnswers, setAllAnswers }) => {
  const [field, meta, helpers] = useField(question?.toLowerCase().replace(/\s/g, ''));
  const { setValue } = helpers;
  const [checked, setChecked] = useState(false);
  return (
    <div className="flex flex-col space-y-4 w-full max-w-[384px] mx-auto">
      {options.map((option, i) => (
        <SelectOption
          key={i}
          {...option}
          question={question}
          checked={checked}
          setChecked={setChecked}
          allAnswers={allAnswers}
          setAllAnswers={setAllAnswers}
          setValue={setValue}
        />
      ))}
    </div>
  );
};

export const SelectOption = ({
  label,
  question,
  checked,
  setChecked,
  allAnswers,
  setAllAnswers,
  setValue,
  showIcon = false,
  icon,
}) => {
  function setCurrentAnswer(label) {
    setAllAnswers({ ...allAnswers, [String(question)]: label });
    setChecked(label);
    setValue(label);
  }

  return (
    <label
      className={`bg-white rounded-[20px]${
        showIcon === true
          ? '  card-shadow flex flex-col space-y-8 items-center justify-center py-10 px-5 h-[177px]  border border-white transition-colors duration-300 ease-in-out hover:border-blue'
          : ' text-navy card-shadow py-5 px-10 text-center border border-white transition-colors duration-300 ease-in-out hover:border-blue'
      } 
	  ${checked && checked === label ? 'border-blue' : ''} 
	  `}
    >
      <Field
        type="radio"
        name={question?.toLowerCase().replace(/\s/g, '')}
        value={label}
        className="hidden"
        onChange={() => setCurrentAnswer(label)}
      />
      {showIcon && <div className="text-blue ">{icon === true ? <Check /> : <Cross />}</div>}
      <div className="t-18 font-heading font-bold">{label}</div>
    </label>
  );
};

const InformationQuestion = () => {
  return (
    <div className="space-y-4 max-w-[995px] mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="col-span-1 md:col-span-2">
          <div className="bg-white rounded-[20px] card-shadow px-10 md:px-14 py-16 md:py-20 border text-center md:text-left border-white h-full space-y-11">
            <h3 className="t-45">Here for every step of your wellness journey</h3>
            <div className="space-y-2">
              <div className="flex flex-wrap gap-2 md:gap-7 items-center">
                <StaticImage
                  src="../../assets/images/star.png"
                  alt="star"
                  className="h-[48px] max-w-[42px] mx-auto md:m-0"
                  objectFit="contain"
                />
                <p className="text-black/60 w-full md:w-auto">45 day money back guarantee</p>
              </div>
              <div className="flex flex-wrap gap-2 md:gap-7 items-center">
                <StaticImage
                  src="../../assets/images/warrantyIcon.png"
                  alt="warranty"
                  className="h-[48px] max-w-[42px] mx-auto md:m-0"
                  objectFit="contain"
                />
                <p className="text-black/60 w-full md:w-auto">3 year warranty</p>
              </div>
              <div className="flex flex-wrap gap-2 md:gap-7 items-center">
                <StaticImage
                  src="../../assets/images/homeIcon.png"
                  alt="Home"
                  className="h-[48px] max-w-[42px] mx-auto md:m-0"
                  objectFit="contain"
                />
                <p className="text-black/60 w-full md:w-auto">Home fitting & service</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="relative space-y-7 h-full">
            <div className="rounded-[8px] overflow-hidden">
              <StaticImage src="../../assets/images/AbbySmith.png" alt="AbbySmith" className="" objectFit="contain" />
            </div>
            <div className="space-y-1 text-center lg:text-left">
              <div className="t-21-normal leading-[-.02em] text-navy">
                <strong>Dr. Abby Smith</strong> AU.D
              </div>
              {location && (
                <div className="t-18 font-medium text-green space-x-1.5 flex items-center justify-center lg:justify-start">
                  <Pin />
                  <div>TX</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function validateFullName(value) {
  let error;
  if (value === '') {
    error = 'This field is required';
  }
  return error;
}

const UserDetails = ({ index, question, questionsLength, allAnswers, setAllAnswers }) => {
  return (
    <>
      <div className="space-y-4 max-w-[628px] mx-auto">
        <div className="t-14 font-semibold text-blue">
          {index + 1} of {questionsLength}
        </div>
        <h2 className="t-42">{question}</h2>
      </div>
      <div className="relative w-full space-y-4 max-w-[628px] mx-auto">
        <Field
          type="text"
          placeholder="Your full name"
          className="bg-white text-navy rounded-[50px] py-3 px-7 border border-navy/20 w-full"
          label=""
          name="fullName"
          validate={(value) => {
            if (!value) return 'This field is required';
          }}
        ></Field>
        <Field
          type="email"
          placeholder="Your email address"
          className="bg-white text-navy rounded-[50px] py-3 px-7 border border-navy/20 w-full"
          label=""
          name="emailAddress"
          validate={(value) => {
            if (!value) return 'This field is required';
          }}
        ></Field>
        <Field
          type="tel"
          placeholder="Your phone number"
          className="bg-white text-navy rounded-[50px] py-3 px-7 border border-navy/20 w-full"
          label=""
          name="phoneNumber"
          validate={(value) => {
            if (!value) return 'This field is required';
          }}
        ></Field>
        <Field
          type="text"
          placeholder="Your age"
          className="bg-white text-navy rounded-[50px] py-3 px-7 border border-navy/20 w-full"
          label=""
          name="age"
          validate={(value) => {
            if (!value) return 'This field is required';
          }}
        ></Field>
        <label className="block">
          <Field
            type="checkbox"
            name="terms"
            className="mr-3"
            validate={(value) => {
              if (!value) return 'This field is required';
            }}
          />
          By clicking continue, you consent to receive marketing calls and text messages from YesHearing. Consent is not
          a condition of purchase. Read our
          <Link to="/terms-conditions/" className="underline">
            Privacy Policy and Terms & Conditions.
          </Link>
        </label>
      </div>
    </>
  );
};
