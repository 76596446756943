import React from 'react';
import Link from 'gatsby-link';
import { Star } from '~/components/elements/Icon';
import { ProductTag } from '../Products';
import { Price } from '../Products';
import Image from '~/components/elements/Image';
import { Button } from '../../elements/Link';
// Single Product

export const SingleProduct = ({ title, image, price, showPrice, slug, ratings, productTag }) => {
  return (
    <Link to={`/product/${slug}`}>
      <div className="h-full space-y-2">
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              {productTag && <ProductTag productTag={productTag} />}
              <div className="flex items-center space-x-1 text-blue">
                {[...Array(ratings)].map((item, i) => (
                  <Star key={i} />
                ))}
              </div>
            </div>
            <h5 className="t-20 h-[65px]">{title}</h5>
            <div className="h-[250px] w-full flex">
              <Image className="!absolute" image={image} class="w-full max-h-[200px]" />
            </div>
          </div>
          <div>
            <div className="space-y-2">
              {showPrice ? (
                <Price amount={price} />
              ) : (
                <>
                  <div className="italic t-12px text-center">Call for Pricing</div>
                </>
              )}
            </div>
            <div className="fw-button !mt-6">
              <Button link={{ url: `/product/${slug}`, title: 'View product' }} buttonStyle="outline" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
