import React from 'react';

//RESULTS

function findProducts(products, productSearch = []) {
  const finalProducts = products.filter((product) => {
    return productSearch.some((p) => p === product.name);
  });
  return finalProducts;
}

export function findYourProductResults(allAnswers, allProducts) {
  const questionOne = ['Bluetooth connectivity', 'Rechargeable devices', 'Speech clarity'];
  const questionTwo = ['700 or more', '600-699', "I don't know"];
  let finalProducts = [];

  //Answer = Bluetooth connectivity || Rechargeable || Speech Clarity
  if (questionOne.some((el) => Object.values(allAnswers).includes(el))) {
    //Answer = 700 or more OR 600-699 OR I don’t know
    if (questionTwo.some((el) => Object.values(allAnswers).includes(el))) {
      let products = ['Phonak Audéo Paradise 90', 'Oticon MORE 1 Rechargeable', 'Resound ONE 9']; //

      if (Object.values(allAnswers).includes('Phonak')) {
        products = ['Phonak Audéo Paradise 90', 'Phonak Audéo Paradise 70']; //
      }
      if (Object.values(allAnswers).includes('Oticon')) {
        products = ['Oticon MORE 1 Rechargeable', 'Oticon MORE 2 Rechargeable', 'Phonak Audéo Paradise 90']; //
      }
      if (Object.values(allAnswers).includes('Resound')) {
        products = ['Resound ONE 9', 'Resound ONE 7', 'Oticon MORE 1 Rechargeable']; //
      }
      if (Object.values(allAnswers).includes('Signia')) {
        products = ['Signia Styletto 7 AX Kit', 'Signia Pure Charge & Go 7AX', 'Signia Motion Charge & Go 7X BTE']; //
      }
      if (Object.values(allAnswers).includes('Starkey')) {
        products = ['Starkey Evolv AI 2400 RIC / BTE', 'Starkey Evolv AI 2000 RIC / BTE', 'Oticon MORE 1 Rechargeable']; //
      }

      finalProducts = findProducts(allProducts, products);
      return finalProducts;

      //Answer = 599 or less
    } else {
      let products = ['Phonak Audéo Paradise 70', 'Oticon MORE 2 Rechargeable', 'Signia Styletto 3 AX Kit']; //

      if (Object.values(allAnswers).includes('Phonak'))
        products = ['Phonak Audéo Paradise 70', 'Phonak Audéo Paradise 50', 'Oticon MORE 2 Rechargeable']; //
      if (Object.values(allAnswers).includes('Oticon'))
        products = ['Oticon MORE 2 Rechargeable', 'Oticon MORE 3 Rechargeable', 'Phonak Audéo Paradise 70']; //
      if (Object.values(allAnswers).includes('Resound'))
        products = ['Resound ONE 7', 'Resound ONE 5', 'Oticon MORE 2 Rechargeable']; //
      if (Object.values(allAnswers).includes('Signia'))
        products = ['Signia Styletto 5 AX Kit', 'Signia Styletto 3 AX Kit', 'Signia Pure Charge & Go 5AX']; //
      if (Object.values(allAnswers).includes('Starkey'))
        products = ['Starkey Evolv AI 2000 RIC / BTE', 'Starkey Evolv AI 1600 RIC / BTE', 'Oticon MORE 3 Rechargeable']; //

      finalProducts = findProducts(allProducts, products);
      return finalProducts;
    }
    //Answer = Smaller Devices
  } else {
    //Answer = 700 or more OR 600-699 OR I don’t know
    if (questionTwo.some((el) => Object.values(allAnswers).includes(el))) {
      let products = ['Starkey Evolv AI 2400 (ITE/ITC)', 'Signia Active / Active Pro X']; //

      if (Object.values(allAnswers).includes('Phonak'))
        products = ['Phonak Audéo Paradise 90', 'Phonak Audéo Paradise 70', 'Starkey Evolv AI 2400 (ITE/ITC)']; //
      if (Object.values(allAnswers).includes('Oticon'))
        products = ['Oticon MORE 1 Rechargeable', 'Oticon MORE 2 Rechargeable', 'Starkey Evolv AI 2400 (ITE/ITC)']; //
      if (Object.values(allAnswers).includes('Resound'))
        products = ['Resound ONE 9', 'Resound ONE 7', 'Signia Active / Active Pro X']; //
      if (Object.values(allAnswers).includes('Signia'))
        products = ['Signia Active / Active Pro X', 'Starkey Evolv AI 2400 (ITE/ITC)']; //
      if (Object.values(allAnswers).includes('Starkey'))
        products = ['Starkey Evolv AI 2400 (ITE/ITC)', 'Signia Active / Active Pro X']; //

      finalProducts = findProducts(allProducts, products);
      return finalProducts;

      //Answer = 599 or less
    } else {
      let products = ['Starkey Evolv AI 1600 (ITE/ITC-R)', 'Signia Silk 5 X']; //
      if (Object.values(allAnswers).includes('Phonak'))
        products = ['Phonak Audéo Paradise 70', 'Phonak Audéo Paradise 50', 'Starkey Evolv AI 2000 (ITE/ITC-R)']; //
      if (Object.values(allAnswers).includes('Oticon'))
        products = ['Oticon MORE 2 Rechargeable', 'Oticon MORE 3 Rechargeable', 'Starkey Evolv AI 2000 (ITE/ITC-R)']; //
      if (Object.values(allAnswers).includes('Resound'))
        products = ['Resound ONE 7', 'Resound ONE 5', 'Signia Active / Active Pro X']; //
      if (Object.values(allAnswers).includes('Signia'))
        products = ['Signia Active / Active Pro X', 'Starkey Evolv AI 2000 (ITE/ITC-R)']; //
      if (Object.values(allAnswers).includes('Starkey')) products = ['Starkey Evolv AI 2000 (ITE/ITC-R)'];

      finalProducts = findProducts(allProducts, products);
      return finalProducts;
    }
  }
}
