import { graphql, useStaticQuery } from 'gatsby';

export const useAllProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpProduct {
        nodes {
          id
          name
          sku
          slug
          featured
          postTypeProduct {
            productVendor
            productDescription
          }
          reviews {
            averageRating
          }
          attributes {
            nodes {
              id
              ... on WpGlobalProductAttribute {
                slug
              }
              options
            }
          }
          ... on WpSimpleProduct {
            id
            name
            price
            postTypeProduct {
              showPrice
              tag
            }
            description
            featured
            featuredImage {
              node {
                ...Image
              }
            }
            regularPrice
            salePrice
          }
          ... on WpVariableProduct {
            id
            name
            price
            postTypeProduct {
              showPrice
              tag
            }
            description
            featured
            featuredImage {
              node {
                ...Image
              }
            }
            regularPrice
            salePrice
          }
          productCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
  `);

  return data.allWpProduct.nodes;
};
