import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';

const Sitemap = (data) => {
  const sitemap = useStaticQuery(graphql`
    query Sitemap {
      allWpPage {
        nodes {
          title
          uri
        }
      }
      allWpProduct {
        nodes {
          link
          name
        }
      }
      allWpPost {
        nodes {
          title
          link
        }
      }
    }
  `);
  return <SitemapContainer pages={sitemap?.allWpPage} products={sitemap?.allWpProduct} posts={sitemap?.allWpPost} />;
};

export default Sitemap;

const SitemapContainer = ({ pages, products, posts }) => {
  const allPages = pages?.nodes?.sort(function (a, b) {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  const allProducts = products?.nodes?.sort(function (a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const allPosts = posts?.nodes?.sort(function (a, b) {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="container mt-12 mb-28">
      <h1 className="t-32 mb-12">Sitemap</h1>
      <div className="space-y-20">
        {allPages && (
          <div>
            <h2 className="t-24 mb-6">Main</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {allPages?.map((page, i) => {
                return (
                  <Link key={`page-${i}`} to={page?.uri} className="hover:underline">
                    <div>{page?.title}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        {allProducts && (
          <div>
            <h2 className="t-24 mb-6">Products</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {allProducts?.map((product, i) => {
                return (
                  <a key={`product-${i}`} href={product?.link} className="hover:underline">
                    <div>{product?.name}</div>
                  </a>
                );
              })}
            </div>
          </div>
        )}
        {allPosts && (
          <div>
            <h2 className="t-24 mb-6">Blog</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {allPosts?.map((post, i) => {
                return (
                  <a key={`post-${i}`} href={post?.link} className="hover:underline">
                    <div>{post?.title}</div>
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
